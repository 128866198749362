import { chakra, HTMLChakraProps } from "@chakra-ui/react";

const _Video = chakra("video");

export interface IVideoProps extends HTMLChakraProps<"video"> {
    alt?: string;
    previewImageUrl?: string;
}

export function Video({ alt, previewImageUrl, ...props }: IVideoProps) {
    return <_Video aria-describedby={alt} poster={previewImageUrl} {...props} />;
}

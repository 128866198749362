import { BoxProps } from "@chakra-ui/react";
import { ScrollSnapItem } from "../scroll-snap";
import { HorizontalScrollSnap } from "./horizontal-scroll-snap";

interface IHorizontalScrollSnapCards<T> {
    cardsData: T[];
    Card: React.ElementType;
    cardProps?: BoxProps & Record<string, unknown>;
}

export const CARDS_WIDTH = ["288px", "358px", "auto"];

// A wrapper style for ScrollSnapCards that maintains consistent styling across various sections of the application.
export function HorizontalScrollSnapCards<T extends { id?: string }>(props: IHorizontalScrollSnapCards<T>) {
    const { cardsData, cardProps, Card } = props;

    return (
        <HorizontalScrollSnap>
            {cardsData.map((data, index) => (
                <ScrollSnapItem key={data?.id ?? index} pr={index === 2 ? [2, 2, 0] : undefined}>
                    <Card data={data} w={CARDS_WIDTH} {...cardProps} />
                </ScrollSnapItem>
            ))}
        </HorizontalScrollSnap>
    );
}

import { PropsWithChildren } from "react";
import { Box, Flex, BoxProps, Image } from "@chakra-ui/react";
import NextImage from "next/legacy/image";
import { IImage } from "src/types/common";
import { EnumHorizontalAlignment } from "src/types/common-enum";
import { buildUrl } from "src/utils/responsive-image-util";
import { Video } from "../../base/video";

const SMARTPHONE_MARGIN_TO_BORDER_DESKTOP = 200;
const SMARTPHONE_BIG_WIDTH = 292;

const LAPTOP_WIDTH = 460;
const LAPTOP_BIG_WIDTH = 550;

const Smartphone = (props: BoxProps) => (
    <Flex
        pos="relative"
        mt={[3, 5, 10]}
        mb={[0, 0, 10]}
        mx="auto"
        w={["181px", `${SMARTPHONE_BIG_WIDTH}px`]}
        h={["373px", "602px"]}
        bg="white"
        border="1px solid"
        borderColor="gray.100"
        borderRadius="25px"
        boxShadow={[
            "inset 6px -5px 4px 0 rgba(10, 197, 137, 0.06), inset 2px -1px 7px 0 rgba(188, 188, 212, 0.44), inset 0 1px 4px 0 rgba(188, 188, 212, 0.44)",
            "inset 10px -8px 6px 0 rgba(10, 197, 137, 0.06), inset 3px -1px 12px 0 rgba(188, 188, 212, 0.44), inset 0 1px 6px 0 rgba(188, 188, 212, 0.44)",
        ]}
        p={["31px 14px 43px", "50px 23px 69px"]}
        _after={{
            content: '""',
            pos: "absolute",
            left: "0px",
            right: "0px",
            bottom: ["2px", "-1px"],
            h: ["10px", "20px"],
            zIndex: -1,
            borderRadius: "35%",
            bg: "rgb(0 0 0 / 9%)",
            boxShadow: "0px 0px 18px 20px rgb(0 0 0 / 10%)",
        }}
        {...props}
    />
);

const Laptop = ({ children, ...boxProps }: PropsWithChildren<BoxProps>) => (
    <Box
        pos="relative"
        my={10}
        minWidth={[0, 0, `${LAPTOP_WIDTH}px`, `${LAPTOP_BIG_WIDTH}px`]}
        _after={{
            content: '""',
            pos: "absolute",
            left: "8px",
            right: "8px",
            bottom: "10px",
            h: "7px",
            zIndex: -1,
            borderRadius: "25px",
            bg: "rgb(0 0 0 / 10%)",
            boxShadow: "0px 0px 11px 10px rgb(0 0 0 / 14%)",
        }}
        {...boxProps}
    >
        <NextImage alt="laptop" src="/img/laptop.png" width={LAPTOP_BIG_WIDTH} height={381} />
        <Flex
            pos="absolute"
            justify="center"
            left="1px"
            top="0"
            w="100%"
            h={[0, 0, "240px", "286px"]}
            px={[0, 0, "68px", "80px"]}
            pt={[0, 0, "17px", "23px"]}
        >
            {children}
        </Flex>
    </Box>
);

interface IMockProps {
    image?: IImage;
    video?: IImage;
}
const Mock = ({ image, video }: IMockProps) => {
    return video ? (
        <Video
            flexGrow={1}
            src={video.url}
            alt={video.alt ?? undefined}
            previewImageUrl={image?.url}
            autoPlay
            loop
            muted
            disablePictureInPicture
            playsInline
            preload="none"
        />
    ) : (
        <Image flexGrow={1} src={buildUrl(image?.url)} alt={image?.alt ?? undefined} loading="lazy" />
    );
};

interface IDeviceProps {
    laptopMock: IMockProps;
    phoneMock: IMockProps;
    mockupAlign: EnumHorizontalAlignment;
}
export const Device = ({ laptopMock, phoneMock, mockupAlign }: IDeviceProps) => {
    const hasLaptopMock = Boolean(laptopMock.image) || Boolean(laptopMock.video);
    const smartPhoneProp = {
        [mockupAlign === EnumHorizontalAlignment.Right ? "mr" : "ml"]: [null, null, null, `${SMARTPHONE_MARGIN_TO_BORDER_DESKTOP}px`],
    };

    if (hasLaptopMock) {
        return (
            <>
                <Laptop display={{ base: "none", md: "block" }}>
                    <Mock {...laptopMock} />
                </Laptop>
                <Smartphone {...smartPhoneProp} display={{ base: "flex", md: "none" }}>
                    <Mock {...phoneMock} />
                </Smartphone>
            </>
        );
    }
    return (
        <Smartphone {...smartPhoneProp}>
            <Mock {...phoneMock} />
        </Smartphone>
    );
};

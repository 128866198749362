import { AspectRatio, AspectRatioProps, Box, Image, ImageProps, ResponsiveValue } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { createChartImagePreviewUrl } from "src/consts/urls";

export interface IWikifolioOgChartImageProps extends AspectRatioProps {
    wikifolioId: string;
    shortDescription: string;
    ratio?: ResponsiveValue<number>;
    loading?: ImageProps["loading"];
    onError?: () => void;
}

export const WikifolioOgChartImage = ({
    wikifolioId,
    ratio = 16 / 9,
    shortDescription,
    loading,
    onError,
    ...aspectRatioProps
}: IWikifolioOgChartImageProps) => {
    const { t } = useTranslation("common");

    const onErrorHandle = () => onError && onError();

    return (
        <AspectRatio ratio={ratio} {...aspectRatioProps}>
            <Box>
                <Image
                    src={createChartImagePreviewUrl(wikifolioId)}
                    alt={`${t("wikifolio-chart")}: ${shortDescription}`}
                    w="100%"
                    h="100%"
                    loading={loading}
                    onError={onErrorHandle}
                />
            </Box>
        </AspectRatio>
    );
};

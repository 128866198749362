import { useEffect } from "react";
import { Box, BoxProps, Flex, HStack, StackDirection } from "@chakra-ui/react";
import { WfLinkGhost } from "src/components/base/wf-link";
import { RankingStack } from "src/components/common/ranking/ranking-stack";
import { TraderAvatar } from "src/components/common/trader-avatar";
import { WikifolioOgChartImage } from "src/components/common/wikifolio-og-chart-image";
import { IBestsellerData } from "src/components/modules/bestseller-module/bestseller.types";
import { useGetUserName } from "src/hooks/use-get-user-name";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useWikifolioStore } from "src/stores/wikifolio-store";
import { BestsellerActionButtons } from "./bestseller-action-buttons";

export interface IBestsellerProps extends BoxProps {
    data: IBestsellerData;
    buttonsDirection?: StackDirection;
    hideChart?: boolean;
    addedToWatchlistMessage?: string;
    removedFromWatchlistMessage?: string;
}

export const Bestseller = ({
    data: { trader, kpis, actions, id, isOnWatchlist, ...wfData },
    buttonsDirection = ["column", "row-reverse", "column", "row-reverse"],
    hideChart = false,
    addedToWatchlistMessage,
    removedFromWatchlistMessage,
    ...boxProps
}: IBestsellerProps) => {
    const { buildTraderProfileLink, buildWikifolioLink } = useLinkBuilder();
    const { watchlist, setOnWatchlist } = useWikifolioStore();
    const getUserName = useGetUserName();

    useEffect(() => {
        setOnWatchlist(id, isOnWatchlist);
    }, [id, isOnWatchlist, setOnWatchlist]);

    const bestsellerProps = { ...wfData, id, isOnWatchlist: watchlist[id] ?? false, setOnWatchlist };

    const rankings = kpis.map(kpi => ({ data: kpi }));
    const traderProfileLink = buildTraderProfileLink(trader.nickName);

    return (
        <Box flex={1} boxShadow="base" rounded="8px" p={[2, 3]} {...boxProps}>
            <HStack spacing={1} mb={3}>
                <TraderAvatar size="md" trader={trader} href={traderProfileLink} />
                <Flex flexDir="column" flexGrow={1} overflow="hidden">
                    <WfLinkGhost href={buildWikifolioLink(wfData.symbol)} mb={0.5} noOfLines={1} wordBreak="break-all">
                        {wfData.shortDescription}
                    </WfLinkGhost>
                    <WfLinkGhost href={traderProfileLink} fontWeight="normal" fontSize="sm" noOfLines={1} wordBreak="break-all">
                        {getUserName(trader)}
                    </WfLinkGhost>
                </Flex>
            </HStack>
            <RankingStack size="md" rankings={rankings} mx={[-2, -3]} mb={3} hidePoints />
            {!hideChart && <WikifolioOgChartImage loading="lazy" wikifolioId={id} shortDescription={wfData.shortDescription} mb={3} mx={[-2, -3]} />}
            <BestsellerActionButtons
                addedToWatchlistMessage={addedToWatchlistMessage}
                removedFromWatchlistMessage={removedFromWatchlistMessage}
                buttonsDirection={buttonsDirection}
                actions={actions}
                {...bestsellerProps}
            />
        </Box>
    );
};

import { Stack, StackDivider, StackProps } from "@chakra-ui/react";
import { DescriptionList } from "../../base";
import { IRanking, Ranking } from "./ranking";
import { IRankingValueSize } from "./ranking-value";

export interface IRankingStackProps extends StackProps {
    size: IRankingValueSize;
    rankings: Omit<IRanking, "size">[];
    hidePoints?: boolean;
}
export const RankingStack = ({ size, rankings, hidePoints, ...stackProps }: IRankingStackProps) => {
    return (
        <Stack as={DescriptionList} direction="row" justify="space-between" align="flex-start" spacing={0} divider={<StackDivider />} {...stackProps}>
            {rankings.map((r, idx) => (
                <Ranking key={idx} size={size} flex={1} mx={0.5} isDescriptionListItem {...r} hidePoints={hidePoints} />
            ))}
        </Stack>
    );
};

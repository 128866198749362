import { useTranslation } from "next-i18next";
import { Button, ButtonProps } from "src/components/base/button";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { useLinkBuilder } from "src/hooks/use-link-builder";

interface IGoToWikifolioButtonProps extends ButtonProps {
    symbol?: string;
    href?: string;
}

export const GoToWikifolioButton = ({ symbol, onClick, href, ...buttonProps }: IGoToWikifolioButtonProps) => {
    const { buildWikifolioLink } = useLinkBuilder();
    const { t } = useTranslation("common");
    const link = href ?? (symbol && buildWikifolioLink(symbol));

    return (
        <Button as={WfLinkUnstyled} href={link} onClick={onClick} {...buttonProps}>
            {t("to-wikifolio")}
        </Button>
    );
};

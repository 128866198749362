import { Box, Stack } from "@chakra-ui/react";
import { SingleCta } from "src/components/common/cta/cta";
import { ModulePrimaryHeadline, ModuleSecondaryHeadline } from "src/components/common/module-headlines";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container, ContainerBig, CONTAINER_PX } from "src/components/layout/container";
import { IImage } from "src/types/common";
import { EnumHorizontalAlignment } from "src/types/common-enum";
import { ICta } from "src/types/cta";
import { Device } from "./devices";

const GrayBackground = ({ mockupAlign }: { mockupAlign: EnumHorizontalAlignment }) => (
    <Box
        pos="absolute"
        top="0"
        left={[-CONTAINER_PX[0], -CONTAINER_PX[1], mockupAlign === EnumHorizontalAlignment.Right ? "auto" : 0]}
        right={[-CONTAINER_PX[0], -CONTAINER_PX[1], mockupAlign === EnumHorizontalAlignment.Right ? 0 : null]}
        w={["auto", "auto", "23%", "35%"]}
        maxW={[null, null, null, "500px"]}
        h={["339px", "342px", "100%"]}
        bg="gray.50"
        zIndex="-1"
    />
);

export interface IMockupTextModuleProps {
    headline?: string;
    title?: string;
    titleAsH2: boolean;
    description: string;
    mockupAlign: EnumHorizontalAlignment;
    cta?: ICta;
    phoneMockImage: IImage;
    phoneMockVideo?: IImage;
    laptopMockImage?: IImage;
    laptopMockVideo?: IImage;
}

export const MockupTextModule = ({
    headline,
    title,
    titleAsH2,
    description,
    cta,
    mockupAlign,
    phoneMockImage,
    phoneMockVideo,
    laptopMockImage,
    laptopMockVideo,
}: IMockupTextModuleProps) => {
    return (
        <>
            {headline && (
                <Container>
                    <ModulePrimaryHeadline mb={[4, 5]} textAlign={["left", "left", "center"]}>
                        {headline}
                    </ModulePrimaryHeadline>
                </Container>
            )}
            <ContainerBig pos="relative">
                <Container>
                    <Stack
                        direction={["column", "column", mockupAlign === EnumHorizontalAlignment.Right ? "row" : "row-reverse"]}
                        spacing={[4, 4, 8]}
                        alignItems={["stretch", "stretch", "center"]}
                    >
                        <Box flexGrow={1}>
                            {title && <ModuleSecondaryHeadline titleAsH2={titleAsH2}>{title}</ModuleSecondaryHeadline>}
                            <SimpleRichText mb={cta ? 3 : 0} fontSize={["md", "lg"]} text={description} />
                            {cta && <SingleCta {...cta} />}
                        </Box>
                        <Box pos={["relative", "relative", "static"]}>
                            <GrayBackground mockupAlign={mockupAlign} />
                            <Device
                                {...{
                                    mockupAlign,
                                    phoneMock: { image: phoneMockImage, video: phoneMockVideo },
                                    laptopMock: { image: laptopMockImage, video: laptopMockVideo },
                                }}
                            />
                        </Box>
                    </Stack>
                </Container>
            </ContainerBig>
        </>
    );
};

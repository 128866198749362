import { Stack, StackDirection } from "@chakra-ui/react";
import { ButtonProps } from "src/components/base/button";
import { CopyIsinButton } from "src/components/common/buttons/copy-isin-button/copy-isin-button";
import { GoToWikifolioButton } from "src/components/common/buttons/go-to-wikifolio-button/go-to-wikifolio-button";
import { InvestButton } from "src/components/common/buttons/innvest-button/invest-button";
import { WatchlistButton } from "src/components/common/buttons/watchlist-button/watchlist-button";
import { IBestsellerWikifolio } from "src/components/modules/bestseller-module/bestseller.types";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { EnumWfActionType } from "src/types/common-enum";

interface ISingleActionButtonProps extends IBestsellerWikifolio {
    actionType: EnumWfActionType;
    isPrimary?: boolean;
    setOnWatchlist: (id: string, isOnWatchList: boolean) => void;
    addedToWatchlistMessage?: string;
    removedFromWatchlistMessage?: string;
}
const SingleActionButton = ({
    id: wikifolioId,
    symbol,
    isin,
    shortDescription,
    isLicensed,
    isOnWatchlist,
    setOnWatchlist,
    actionType,
    isPrimary,
    addedToWatchlistMessage,
    removedFromWatchlistMessage,
}: ISingleActionButtonProps) => {
    const btnProps: ButtonProps = {
        variant: isPrimary ? "outline" : "ghost",
        width: "100%",
        size: "sm",
    };

    const { buildWikifolioLink } = useLinkBuilder();

    switch (actionType) {
        case EnumWfActionType.CopyIsin:
            return <CopyIsinButton {...btnProps} isin={isin} isDisabled={!isLicensed} />;
        case EnumWfActionType.GoToWf:
            return <GoToWikifolioButton {...btnProps} href={buildWikifolioLink(symbol)} />;
        case EnumWfActionType.Watchlist:
            return (
                <WatchlistButton
                    {...btnProps}
                    {...{ wikifolioId, isOnWatchlist, setOnWatchlist }}
                    wikifolioShortDescription={shortDescription}
                    addedToWatchlistMessage={addedToWatchlistMessage}
                    removedFromWatchlistMessage={removedFromWatchlistMessage}
                />
            );
        case EnumWfActionType.Invest:
            return <InvestButton {...btnProps} {...{ wikifolioId, isin }} isDisabled={!isLicensed} />;
        default:
            return null;
    }
};

export interface IBestsellerActionButtonsProps extends IBestsellerWikifolio {
    actions: EnumWfActionType[];
    buttonsDirection?: StackDirection;
    setOnWatchlist: (id: string, isOnWatchList: boolean) => void;
    addedToWatchlistMessage?: string;
    removedFromWatchlistMessage?: string;
}

export const BestsellerActionButtons = ({ buttonsDirection, actions, ...wfData }: IBestsellerActionButtonsProps) => (
    <Stack direction={buttonsDirection} spacing={2}>
        <SingleActionButton actionType={actions[0]} {...wfData} isPrimary />
        <SingleActionButton actionType={actions[1]} {...wfData} />
    </Stack>
);

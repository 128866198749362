import { PropsWithChildren } from "react";
import { CONTAINER_PX_XS_SM, CONTAINER_PX_XS_SM_NEGATIVE } from "src/components/layout/container";
import { ScrollSnap } from "../scroll-snap";

interface IHorizontalScrollSnap extends PropsWithChildren {}

export function HorizontalScrollSnap({ children }: IHorizontalScrollSnap) {
    return (
        <ScrollSnap
            p={[...CONTAINER_PX_XS_SM, 0]}
            m={[...CONTAINER_PX_XS_SM_NEGATIVE, 0]}
            gap={[2, 3]}
            overflowX={["auto", "auto", "visible"]}
            templateColumns={["repeat(3, auto)", "repeat(3, auto)", "repeat(3, minmax(0, 1fr))"]}
        >
            {children}
        </ScrollSnap>
    );
}
